import { createSelector } from 'reselect';
import * as selectors from 'selectors/selectors';

export const currActionSelector = createSelector(
  state => selectors.currActionsSelector(state),
  state => state.currActionId,
  (actions, currActionId) => {
    return actions.find(a => a.actionId === currActionId) || {};
  }
);
