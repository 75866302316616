import 'styles/Project/DeleteProject.scss';
import React from 'react';
import dispatchable from 'actions/dispatchable';
import * as actions from 'actions/actions';
import * as selectors from 'selectors/selectors';
import * as apiService from 'services/apiService';
import { Button, Modal, Spin } from 'antd';
import { LoadingOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import ConfigSection from 'components/Project/ConfigSection';

const { confirm } = Modal;

class DeleteAction extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDeleting: false
    };
  }

  showConfirm = () => {
    confirm({
      title: `Are you sure you want to delete ${this.props.currAction.name}?`,
      icon: <ExclamationCircleFilled style={{ color: '#FD6161' }} />,
      content: 'This action cannot be recovered after deletion!',
      okText: 'Delete Action',
      okButtonProps: {
        type: 'danger'
      },
      onOk: () => {
        this.deleteAction();
      },
      maskClosable: true
    });
  };

  deleteAction = async () => {
    if (this.state.isDeleting) {
      return;
    }

    this.setState({ isDeleting: true });

    try {
      await apiService.deleteAction(this.props.currProject.projectId, this.props.currAction.actionId);
    } catch (e) {
      this.setState({ isDeleting: false });
      return;
    }

    this.setState({ isDeleting: false });
    this.props.dispatch(actions.getAndSetActions(this.props.currProject.projectId));
    this.props.dispatch(actions.setCurrActionId(''));
  };

  render() {
    const buttonText = this.state.isDeleting ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : 'Delete';

    return (
      <ConfigSection className='configTable redBorder'>
        <div className='deleteProject'>
          <div>
            <div className='deleteProjectTitle'>Delete Action</div>
            <div className='deleteProjectWarning'>Caution! Once you delete this action it is gone forever.</div>
          </div>
          <Button disabled={this.state.isDeleting} onClick={this.showConfirm} type='danger'>
            {buttonText}
          </Button>
        </div>
      </ConfigSection>
    );
  }
}

function mapStateToProps(state) {
  return {
    currProject: selectors.currProjectSelector(state),
    currAction: selectors.currActionSelector(state)
  };
}

export default withRouter(dispatchable(DeleteAction, mapStateToProps));
