import 'styles/Project/CopyButton.scss';
import React from 'react';
import { MdContentCopy } from 'react-icons/md';
import { CopyToClipboard } from 'react-copy-to-clipboard';

class CopyButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCopyMessage: false
    };
  }

  onCopy = () => {
    this.setState({ showCopyMessage: true });
    setTimeout(() => this.setState({ showCopyMessage: false }), 3000);
  };

  render() {
    return (
      <div className='configCopyIconContainer'>
        <div className='copySuccessMessage' style={{ visibility: this.state.showCopyMessage ? 'visible' : 'hidden' }}>
          Copied to clipboard!
        </div>
        <CopyToClipboard text={this.props.text} onCopy={this.onCopy}>
          <div className='configCopyIcon'>
            <MdContentCopy />
          </div>
        </CopyToClipboard>
      </div>
    );
  }
}

export default CopyButton;
