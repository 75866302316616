import 'styles/Login/Login.scss';
import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { firebase } from 'fire/firebase';
import { ReactComponent as Logo } from 'images/LogoWithText.svg';

class Login extends React.Component {
  render() {
    const uiConfig = {
      signInFlow: 'popup',
      signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID, firebase.auth.EmailAuthProvider.PROVIDER_ID],
      callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false
      }
    };

    return (
      <div className='loginWrapper'>
        <div className='logoContainer'>
          <Logo />
        </div>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      </div>
    );
  }
}

export default Login;
