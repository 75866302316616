import 'styles/Project/Project.scss';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material-darker.css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/http/http';
import React from 'react';
import dispatchable from 'actions/dispatchable';
import * as selectors from 'selectors/selectors';
import * as actions from 'actions/actions';
import { withRouter } from 'react-router';
import { isEmpty } from 'lodash';
import ProjectInfo from 'components/Project/ProjectInfo';
import SidenavWrapper from 'components/Project/SidenavWrapper';

class Project extends React.Component {
  componentDidMount() {
    const projectId = this.props.match.params.projectId;

    if (!projectId) {
      this.props.history.replace('/');
    } else {
      this.props.dispatch(actions.setCurrProjectId(projectId));
      this.props.dispatch(actions.getAndSetActions(projectId));
    }
  }

  render() {
    if (this.props.loadingProjects || isEmpty(this.props.currProject)) {
      return <div>Loading Project...</div>;
    }

    return <SidenavWrapper page={<ProjectInfo />} />;
  }
}

function mapStateToProps(state) {
  return {
    currProject: selectors.currProjectSelector(state),
    loadingProjects: state.loadingProjects,
    currActionId: state.currActionId
  };
}

export default withRouter(dispatchable(Project, mapStateToProps));
