import 'styles/Project/ManageCollabsModal.scss';
import React from 'react';
import dispatchable from 'actions/dispatchable';
import * as selectors from 'selectors/selectors';
import * as actions from 'actions/actions';
import * as apiService from 'services/apiService';
import { Modal, Input, List, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FaTimes } from 'react-icons/fa';
import * as emailValidator from 'email-validator';

class ManageCollabsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputText: '',
      addUserError: '',
      addUserSuccess: '',
      isLoading: false
    };
  }

  onCancel = () => {
    this.setState({ inputText: '', addUserError: '', addUserSuccess: '', isLoading: false });
    this.props.onCancel();
  };

  onInputChange = e => {
    this.setState({ inputText: e.target.value, addUserError: '', addUserSuccess: '' });
  };

  addCollaborator = async () => {
    this.setState({ addUserError: '', addUserSuccess: '' });

    if (this.state.inputText && !this.props.collabs.includes(this.state.inputText)) {
      if (!emailValidator.validate(this.state.inputText)) {
        this.setState({ addUserError: 'Please input a valid email' });
      } else {
        this.setState({ isLoading: true });

        try {
          await apiService.addCollaborator(this.props.currProject.projectId, this.state.inputText);
          this.props.dispatch(actions.getAndSetProjects());
        } catch (e) {
          this.setState({ isLoading: false });
          switch (e.response.status) {
            case 403: {
              this.setState({ addUserError: 'Can only have up to 5 collaborators.' });
              return;
            }
            case 404: {
              this.setState({
                addUserError: `Adding a user without an account to a project isn't currently supported. ${this.state.inputText} has been notified to make an account. You can add ${this.state.inputText} as a collaborator once an account is created.`
              });
              return;
            }
            default: {
              this.setState({ addUserError: 'Failed to add user. Please try again.' });
              return;
            }
          }
        }

        this.setState({ inputText: '', isLoading: false, addUserSuccess: `Successfully added ${this.state.inputText}` });
      }
    }
  };

  removeCollaborator = async email => {
    this.setState({ isLoading: true, addUserError: '', addUserSuccess: '' });

    try {
      await apiService.removeCollaborator(this.props.currProject.projectId, email);
      this.props.dispatch(actions.getAndSetProjects());
    } catch (e) {
      this.setState({ isLoading: false, addUserError: `Failed to remove ${email}` });
      return;
    }

    this.setState({ isLoading: false, addUserSuccess: `Successfully removed ${email}` });
  };

  isOwner = email => {
    return email === this.props.currProject.ownerEmail;
  };

  render() {
    const emailError = this.state.addUserError && <div className='manageCollabsEmailError'>{this.state.addUserError}</div>;
    const addSuccess = this.state.addUserSuccess && <div className='manageCollabsEmailSuccess'>{this.state.addUserSuccess}</div>;

    const buttonText = this.state.isLoading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} /> : 'Add';

    return (
      <Modal visible={this.props.showModal} footer={null} title={null} closable={false} onCancel={this.onCancel} destroyOnClose={true}>
        <div className='manageCollabsModalTitle'>Manage Collaborators</div>
        <div className='manageCollabsModalInputContainer'>
          <Input className='manageCollabsModalInput' placeholder='Enter email...' onChange={this.onInputChange} value={this.state.inputText} />
          <Button type='primary' onClick={this.addCollaborator}>
            {buttonText}
          </Button>
        </div>
        {emailError}
        {addSuccess}
        <div className='manageCollabsModalListLabel'>Collaborators</div>
        <List
          className='manageCollabsModalList'
          bordered
          dataSource={this.props.collabs}
          size='small'
          renderItem={collab => {
            const isOwner = this.isOwner(collab);
            const isSelf = collab === this.props.user.email;

            const name = isOwner ? (
              <div>
                {collab} <i>(owner)</i>
              </div>
            ) : (
              <div>{collab}</div>
            );

            return (
              <List.Item>
                <div className='collabListItemContainer'>
                  {name}
                  {!isOwner && !isSelf && (
                    <div className='collabListItemRemoveButton' onClick={() => this.removeCollaborator(collab)}>
                      <FaTimes />
                    </div>
                  )}
                </div>
              </List.Item>
            );
          }}
        />
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    currProject: selectors.currProjectSelector(state),
    collabs: selectors.currCollabsSelector(state),
    user: selectors.userSelector(state)
  };
}

export default dispatchable(ManageCollabsModal, mapStateToProps);
