import React from 'react';
import * as selectors from 'selectors/selectors';
import * as actions from 'actions/actions';
import { Switch } from 'antd';
import dispatchable from 'actions/dispatchable';

class ThemeSwitch extends React.Component {
  changeTheme = checked => {
    if (checked) {
      this.props.dispatch(actions.setTheme('darkTheme'));
    } else {
      this.props.dispatch(actions.setTheme('lightTheme'));
    }
  };

  render() {
    return (
      <div className='themeSwitchWrapper'>
        <Switch
          checkedChildren={'Dark'}
          unCheckedChildren={'Light'}
          checked={this.props.theme === 'darkTheme'}
          onChange={this.changeTheme}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    theme: selectors.themeSelector(state)
  };
}

export default dispatchable(ThemeSwitch, mapStateToProps);
