import * as actions from 'actions/actions';

export const permissionsReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.SET_PERMISSIONS: {
      return action.permissions;
    }
    default: {
      return state;
    }
  }
};
