import 'styles/Shared/LoadingScreen.scss';
import React from 'react';
import Logo from 'images/LogoWithText.svg';

class LoadingScreen extends React.Component {
  render() {
    return (
      <div className='loadingScreenWrapper'>
        <img alt='' height='50%' width='50%' src={Logo} />
      </div>
    );
  }
}

export default LoadingScreen;
