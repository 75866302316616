import axios from 'axios';
import { firebase } from 'fire/firebase';

export async function get(url) {
  const authHeader = await getAuthHeader();

  return axios.get(url, authHeader);
}

export async function put(url, params) {
  const authHeader = await getAuthHeader();

  return axios.put(url, params, authHeader);
}

export async function post(url, params) {
  const authHeader = await getAuthHeader();

  return axios.post(url, params, authHeader);
}

export async function del(url) {
  const authHeader = await getAuthHeader();

  return axios.delete(url, authHeader);
}

export async function patch(url, params) {
  const authHeader = await getAuthHeader();

  return axios.patch(url, params, authHeader);
}

async function getAuthHeader() {
  const token = await firebase.auth().currentUser.getIdToken();

  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
}
