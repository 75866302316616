import React from 'react';
import dispatchable from 'actions/dispatchable';
import ModalConfig from 'components/Project/ModalConfig';
import StorageConfig from 'components/Project/StorageConfig';
import { Row, Col } from 'antd';
import GeneralConfig from 'components/Project/GeneralConfig';
import DeleteAction from 'components/Project/DeleteAction';
import ActionConfig from 'components/Project/ActionConfig';

class ActionDetails extends React.Component {
  render() {
    return (
      <Row>
        <Col lg={{ span: 16, offset: 1 }} xs={{ span: 23, offset: 1 }}>
          <ActionConfig />
          <br />
          <GeneralConfig
            actionName='forceRefresh'
            configName='Force Refresh'
            info='Force users to refresh when they receive the update'
            upgradeInfo='Force users to refresh when they receive the update'
          />
          <br />
          <GeneralConfig
            actionName='clearCache'
            configName='Clear Cache'
            info={`Clear your site's browser cache`}
            upgradeInfo={`Clear your site's browser cache`}
          />
          <br />
          <GeneralConfig
            actionName='clearCookies'
            configName='Clear Cookies'
            info={`Clear your site's cookies`}
            upgradeInfo={`Clear your site's cookies. **Upgrade your account to customize which keys get deleted.**`}
          >
            <StorageConfig clearType={'clearAllCookies'} whitelistType={'whiteListCookies'} storageKeys={'cookieKeys'} storageType={'Cookies'} />
          </GeneralConfig>
          <br />
          <GeneralConfig
            actionName='clearLocalStorage'
            configName='Clear Local Storage'
            info={`Clear your site's local storage`}
            upgradeInfo={`Clear your site's local storage. **Upgrade your account to customize which keys get deleted.**`}
          >
            <StorageConfig
              clearType={'clearAllLocalStorage'}
              whitelistType={'whiteListLocalStorage'}
              storageKeys={'localStorageKeys'}
              storageType={'Local Storage'}
            />
          </GeneralConfig>
          <br />
          <GeneralConfig
            actionName='clearSessionStorage'
            configName='Clear Session Storage'
            info={`Clear your site's session storage`}
            upgradeInfo={`Clear your site's session storage. **Upgrade your account to customize which keys get deleted.**`}
          >
            <StorageConfig
              clearType={'clearAllSessionStorage'}
              whitelistType={'whiteListSessionStorage'}
              storageKeys={'sessionStorageKeys'}
              storageType={'Session Storage'}
            />
          </GeneralConfig>
          <br />
          <ModalConfig />
          <br />
          <DeleteAction />
        </Col>
      </Row>
    );
  }
}

export default dispatchable(ActionDetails);
