export const GET_USER = 'GET_USER';
export const SET_USER = 'SET_USER';
export const SET_THEME = 'SET_THEME';

export function getUser() {
  return {
    type: GET_USER
  };
}

export function setUser(user) {
  return {
    type: SET_USER,
    user
  };
}

export function setTheme(theme) {
  return {
    type: SET_THEME,
    theme
  };
}
