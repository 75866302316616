import 'styles/Dashboard/Dashboard.scss';
import React from 'react';
import * as apiService from 'services/apiService';
import * as selectors from 'selectors/selectors';
import * as actions from 'actions/actions';
import dispatchable from 'actions/dispatchable';
import { Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';
import ProjectCard from 'components/Dashboard/ProjectCard';
import CreateProject from 'components/Dashboard/CreateProject';

class Dashboard extends React.Component {
  componentDidMount() {
    this.props.dispatch(actions.setCurrProjectId(null));
  }

  deleteProject = async projectId => {
    await apiService.deleteProject(projectId);
    this.props.dispatch(actions.getAndSetProjects());
  };

  getCreateAccountCard = () => {
    return (
      <Col className='projectCardCol' xs={24} md={8} key='createAccountCard'>
        <CreateProject />
      </Col>
    );
  };

  getProjects = () => {
    return this.props.projects.map(project => {
      return (
        <Col className='projectCardCol' xs={24} md={8} key={project.projectId}>
          <ProjectCard project={project} />
        </Col>
      );
    });
  };

  render() {
    const projectCards = [this.getCreateAccountCard()].concat(this.getProjects());

    return (
      <Row>
        <Col xs={24} lg={{ span: 18, offset: 3 }} xl={{ span: 16, offset: 4 }}>
          <Row gutter={[16, 16]}>{projectCards}</Row>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    projects: selectors.projectsSelector(state)
  };
}

export default withRouter(dispatchable(Dashboard, mapStateToProps));
