import * as actions from 'actions/actions';

export const loadingActionReducer = (state = false, action) => {
  switch (action.type) {
    case actions.SET_LOADING_ACTION: {
      return action.isLoading;
    }
    default: {
      return state;
    }
  }
};
