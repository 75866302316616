import 'styles/Shared/PushUpdateModal.scss';
import React from 'react';
import * as selectors from 'selectors/selectors';
import { Button, Modal, Spin, Select, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import dispatchable from 'actions/dispatchable';
import * as actions from 'actions/actions';
import { FaCheckCircle } from 'react-icons/fa';
import * as apiService from 'services/apiService';
import PreviewModal from 'components/Project/PreviewModal';
import { isEmpty } from 'lodash';

const { Option } = Select;

class PushUpdateModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAction: null,
      isUpdating: false,
      errorMessage: '',
      showModal: false
    };
  }

  componentDidUpdate() {
    if (this.props.project && this.props.project.projectId && isEmpty(this.props.actions[this.props.project.projectId])) {
      this.props.dispatch(actions.getAndSetActions(this.props.project.projectId));
    }
  }

  onCancel = () => {
    this.setState({ isUpdating: false, errorMessage: '', selectedAction: null, showModal: false });
  };

  onChange = actionId => {
    const action = this.props.actions[this.props.project.projectId].find(a => a.actionId === actionId);

    this.setState({ selectedAction: action });
  };

  pushUpdate = async () => {
    if (!this.state.selectedAction && !this.props.action) {
      return;
    }

    this.setState({ isUpdating: true });

    const action = this.props.action || this.state.selectedAction;

    try {
      apiService.pushUpdate(this.props.project.projectId, action.actionId, this.props.userIds);
      message.success('Update Sent');
    } catch (err) {
      message.error('Update Failed');
    }

    this.onCancel();
  };

  renderDescriptionTitle = title => {
    return (
      <div className='actionDescriptionTitle'>
        <FaCheckCircle className='actionDescriptionTitleIcon' />
        <div className='actionDescriptionTitleText'>{title}</div>
      </div>
    );
  };

  renderShowModal = action => {
    if (!action.showModal) {
      return null;
    }

    return (
      <div className='actionDescriptionSection'>
        {this.renderDescriptionTitle('Show Modal')}
        <ul>
          <li>
            Update is <b>{action.updateIsOptional ? 'OPTIONAL' : 'REQUIRED'}</b>
          </li>
          {!action.updateIsOptional && (
            <li>
              Auto refresh after <b>{action.refreshTime}</b> seconds
            </li>
          )}
          <li className='actionDescriptionPreviewModal'>
            <PreviewModal
              refreshTime={action.refreshTime}
              mainTitle={action.modalMainTitle}
              mainText={action.modalMainText}
              btnSize='small'
              id='runActionPreviewModal'
              isOptional={action.updateIsOptional}
            />
          </li>
        </ul>
      </div>
    );
  };

  renderForceRefresh = action => {
    if (!action.actions.includes('forceRefresh')) {
      return null;
    }

    return <div className='actionDescriptionSection'>{this.renderDescriptionTitle('Force Refresh')}</div>;
  };

  renderClearCache = action => {
    if (!action.actions.includes('clearCache')) {
      return null;
    }

    return (
      <div className='actionDescriptionSection'>
        {this.renderDescriptionTitle(
          <>
            Cache: Clear <b>ALL</b>
          </>
        )}
      </div>
    );
  };

  renderClearCookies = action => {
    if (!action.actions.includes('clearCookies')) {
      return null;
    }

    if (action.clearAllCookies) {
      return (
        <div className='actionDescriptionSection'>
          {this.renderDescriptionTitle(
            <>
              Cookies: Clear <b>ALL</b>
            </>
          )}
        </div>
      );
    }

    const title = action.whiteListCookies
      ? this.renderDescriptionTitle(
          <>
            Cookies: Clear <b>ONLY</b> the following keys:
          </>
        )
      : this.renderDescriptionTitle(
          <>
            Cookies: Clear all keys <b>EXCEPT</b>:
          </>
        );

    return (
      <div className='actionDescriptionSection'>
        {title}
        <ul>
          {action.cookieKeys.map((k, i) => (
            <li key={i}>{k}</li>
          ))}
        </ul>
      </div>
    );
  };

  renderClearLocalStorage = action => {
    if (!action.actions.includes('clearLocalStorage')) {
      return null;
    }

    if (action.clearAllLocalStorage) {
      return (
        <div className='actionDescriptionSection'>
          {this.renderDescriptionTitle(
            <>
              Local Storage: Clear <b>ALL</b>
            </>
          )}
        </div>
      );
    }

    const title = action.whiteListLocalStorage
      ? this.renderDescriptionTitle(
          <>
            Local Storage: Clear <b>ONLY</b> the following keys:
          </>
        )
      : this.renderDescriptionTitle(
          <>
            Local Storage: Clear all keys <b>EXCEPT</b>:
          </>
        );

    return (
      <div className='actionDescriptionSection'>
        {title}
        <ul>
          {action.localStorageKeys.map((k, i) => (
            <li key={i}>{k}</li>
          ))}
        </ul>
      </div>
    );
  };

  renderClearSessionStorage = action => {
    if (!action.actions.includes('clearSessionStorage')) {
      return null;
    }

    if (action.clearAllSessionStorage) {
      return (
        <div className='actionDescriptionSection'>
          {this.renderDescriptionTitle(
            <>
              Session Storage: Clear <b>ALL</b>
            </>
          )}
        </div>
      );
    }

    const title = action.whiteListSessionStorage
      ? this.renderDescriptionTitle(
          <>
            Session Storage: Clear <b>ONLY</b> the following keys:
          </>
        )
      : this.renderDescriptionTitle(
          <>
            Session Storage: Clear all keys <b>EXCEPT</b>:
          </>
        );

    return (
      <div className='actionDescriptionSection'>
        {title}
        <ul>
          {action.sessionStorageKeys.map((k, i) => (
            <li key={i}>{k}</li>
          ))}
        </ul>
      </div>
    );
  };

  renderActions = () => {
    const actions = this.props.actions[this.props.project.projectId];

    if (!actions) {
      return null;
    }

    return actions
      .slice()
      .sort((a, b) => (a.name < b.name ? -1 : 1))
      .map(action => {
        return (
          <Option key={action.actionId} value={action.actionId}>
            {action.name}
          </Option>
        );
      });
  };

  renderActionDescription = () => {
    const action = this.props.action || this.state.selectedAction;

    if (!action || !action.actions) {
      return null;
    }

    return (
      <div className='pushUpdateModalDetails'>
        {this.props.action && <div className='selectedActionName'>{action.name}</div>}
        {this.renderShowModal(action)}
        {this.renderClearCache(action)}
        {this.renderClearCookies(action)}
        {this.renderClearLocalStorage(action)}
        {this.renderClearSessionStorage(action)}
        {this.renderForceRefresh(action)}
      </div>
    );
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  cancelModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const buttonText = this.state.isUpdating ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : 'Push Update';
    const updateToText = this.props.userIds ? `to ${this.props.userIds[0]}` : '';

    return (
      <>
        <Button type='primary' onClick={this.showModal}>
          Push Update
        </Button>
        <Modal visible={this.state.showModal} footer={null} title={null} closable={false} onCancel={this.cancelModal} destroyOnClose={true}>
          <div className='pushUpdateModalTitle'>Push Update {updateToText}</div>
          {!this.props.action && (
            <Select showSearch onChange={this.onChange} placeholder='Select an action' style={{ width: '100%' }}>
              {this.renderActions()}
            </Select>
          )}
          {this.renderActionDescription()}
          <div className='pushUpdateModalFooter'>
            <div className='pushUpdateModalError'>{this.state.errorMessage}</div>
            <div className='pushUpdateModalButtons'>
              {!this.state.isUpdating && (
                <Button className='pushUpdateModalCancelButton' size='large' onClick={this.onCancel}>
                  Cancel
                </Button>
              )}
              <Button
                className='pushUpdateModalButton'
                type='primary'
                size='large'
                onClick={this.pushUpdate}
                disabled={!this.state.selectedAction && !this.props.action}
              >
                {buttonText}
              </Button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    actions: selectors.actionsSelector(state)
  };
}

export default dispatchable(PushUpdateModal, mapStateToProps);
