import 'styles/Project/Project.scss';
import React from 'react';
import dispatchable from 'actions/dispatchable';
import * as selectors from 'selectors/selectors';
import * as actions from 'actions/actions';
import { withRouter } from 'react-router';
import { isEmpty } from 'lodash';
import SidenavWrapper from 'components/Project/SidenavWrapper';
import ChannelDetails from 'components/Project/ChannelDetails';

class Channels extends React.Component {
  componentDidMount() {
    const projectId = this.props.match.params.projectId;

    if (!projectId) {
      this.props.history.replace('/');
    } else {
      this.props.dispatch(actions.setCurrProjectId(projectId));
      this.props.dispatch(actions.getAndSetActions(projectId));
    }
  }

  render() {
    if (this.props.loadingProjects || isEmpty(this.props.currProject)) {
      return <div>Loading Project...</div>;
    }

    return <SidenavWrapper page={<ChannelDetails />} />;
  }
}

function mapStateToProps(state) {
  return {
    currProject: selectors.currProjectSelector(state),
    loadingProjects: state.loadingProjects,
    currActionId: state.currActionId
  };
}

export default withRouter(dispatchable(Channels, mapStateToProps));
