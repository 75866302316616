import * as actions from 'actions/actions';

export const currActionIdReducer = (state = '', action) => {
  switch (action.type) {
    case actions.SET_CURR_ACTION_ID: {
      return action.actionId;
    }
    default: {
      return state;
    }
  }
};
