import * as actions from 'actions/actions';

export const userReducer = (state = null, action) => {
  switch (action.type) {
    case actions.SET_USER: {
      return action.user;
    }
    case actions.SET_THEME: {
      return { ...state, theme: action.theme };
    }
    default: {
      return state;
    }
  }
};
