/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import 'theme/variables.scss';
import 'styles/App.scss';
import 'styles/Themes.scss';
import Menu from './components/Menu';
import Account from 'components/Account/Account';
import Dashboard from 'components/Dashboard/Dashboard';
import Project from 'components/Project/Project';
import React from 'react';
import dispatchable from 'actions/dispatchable';
import * as actions from 'actions/actions';
import * as selectors from 'selectors/selectors';
import { Redirect, Route, BrowserRouter, Switch } from 'react-router-dom';
import history from 'appHistory';
import Action from 'components/Project/Action';
import History from 'components/Project/History';
import Channels from 'components/Project/Channels';
import LoadingScreen from 'components/Shared/LoadingScreen';

class App extends React.Component {
  componentDidMount() {
    this.props.dispatch(actions.getAndSetProjects());
    this.props.dispatch(actions.getUser());
  }

  render() {
    if (this.props.loadingProjects) {
      return <LoadingScreen />;
    }

    const connectionsRoute = this.props.permissions.search_connections && <Route path='/Projects/:projectId/Connections' component={Channels} exact />;

    return (
      <BrowserRouter history={history}>
        <div className={this.props.theme}>
          <div className='appWrapper'>
            <Menu />
            <Switch>
              <Route path='/Account' component={Account} exact />
              <Route path='/Dashboard' component={Dashboard} exact />
              <Route path='/Projects/:projectId' component={Project} exact />
              <Route path='/Projects/:projectId/History' component={History} exact />
              {connectionsRoute}
              <Route path='/Projects/:projectId/Actions/:actionId' component={Action} />
              <Redirect from='*' to='/Dashboard' />
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

function mapStateToProps(state) {
  return {
    theme: selectors.themeSelector(state),
    loadingProjects: state.loadingProjects,
    permissions: selectors.permissionsSelector(state)
  };
}

export default dispatchable(App, mapStateToProps);
