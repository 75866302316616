import 'styles/Dashboard/ProjectCard.scss';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { FaCog } from 'react-icons/fa';
import PushUpdateModal from 'components/Shared/PushUpdateModal';
import moment from 'moment';

class ProjectCard extends React.Component {
  goToProject = () => {
    this.props.history.push(`/Projects/${this.props.project.projectId}`);
  };

  render() {
    const project = this.props.project;
    const lastDeploy = project.history ? (
      <div>
        <div>Last Update</div>
        <div>{moment(project.history[0].timestamp).format('MM/DD/YYYY - h:mm A')}</div>
      </div>
    ) : null;

    return (
      <>
        <div className='projectCard secondaryBg'>
          <div className='projectCardBody'>
            <div className='projectCardDetails'>
              <div className='projectCardName'>{project.name}</div>
              {lastDeploy}
            </div>
            <div className='projectCardSettings' onClick={this.goToProject}>
              <FaCog />
            </div>
          </div>
          <div className='projectCardFooter'>
            <PushUpdateModal project={project} />
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ProjectCard);
