import 'styles/Project/Project.scss';
import 'styles/Project/Installation.scss';
import 'styles/Project/ModalConfig.scss';
import React from 'react';
import dispatchable from 'actions/dispatchable';
import * as selectors from 'selectors/selectors';
import { Row, Col } from 'antd';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import CopyButton from 'components/Project/CopyButton';

class Installation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCopyMessage: false
    };
  }

  onCopy = () => {
    this.setState({ showCopyMessage: true });
    setTimeout(() => this.setState({ showCopyMessage: false }), 3000);
  };

  renderConnectionsInstructions = () => {
    if (!this.props.permissions.search_connections) {
      return null;
    }

    const targetedUpdatesText = `// userId is a unique, recognizable user identifier such as email. 
window.Jellysync.setUser(userId);`;

    return (
      <Row>
        <Col span={24}>
          <div className='flexConfigTitle'>
            <div>
              <div className='installationTitle'>Targeted Updates Installation</div>
              <div>Place in code after user has logged in</div>
            </div>
            <CopyButton text={targetedUpdatesText} />
          </div>
          <div className='targetedUpdateCodeBox'>
            <CodeMirror
              height={100}
              value={targetedUpdatesText}
              options={{
                mode: 'text/javascript',
                theme: this.props.theme === 'darkTheme' ? 'material-darker' : 'default',
                lineNumbers: true,
                readOnly: true
              }}
            />
          </div>
        </Col>
      </Row>
    );
  };

  render() {
    const installationText = `<script>
  const jellysync = document.createElement('script');
  jellysync.setAttribute('src', 'https://cdn.jsdelivr.net/npm/jellysync@1.0.65/dist/module.min.js');
  document.head.appendChild(jellysync);
  jellysync.onload = () => {
    window.Jellysync.initialize('${this.props.currProject.projectId}');
  };
</script>`;

    return (
      <Row>
        <Col className='configTable secondaryBg' span={24}>
          <div className='flexConfigTitle'>
            <div className='configTitle'>Installation</div>
            <CopyButton text={installationText} />
          </div>
          <Row>
            <Col span={24}>
              <div>
                Place inside <b>Head</b> tag
              </div>
              <div className='installationCodeBox'>
                <CodeMirror
                  height={100}
                  value={installationText}
                  options={{
                    mode: 'htmlmixed',
                    theme: this.props.theme === 'darkTheme' ? 'material-darker' : 'default',
                    lineNumbers: true,
                    readOnly: true
                  }}
                />
              </div>
            </Col>
          </Row>
          {this.renderConnectionsInstructions()}
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    currProject: selectors.currProjectSelector(state),
    theme: selectors.themeSelector(state),
    permissions: selectors.permissionsSelector(state)
  };
}

export default dispatchable(Installation, mapStateToProps);
