import 'styles/Project/Project.scss';
import React from 'react';
import { Row, Col } from 'antd';

class ConfigSection extends React.Component {
  render() {
    const className = this.props.className || 'configTable secondaryBg';

    return (
      <Row>
        <Col className={className} span={24}>
          {this.props.children}
        </Col>
      </Row>
    );
  }
}

export default ConfigSection;
