import * as actions from 'actions/actions';

export const subscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.SET_SUBSCRIPTION: {
      return action.subscription;
    }
    default: {
      return state;
    }
  }
};
