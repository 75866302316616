import { createSelector } from 'reselect';
import * as selectors from 'selectors/selectors';

export const currActionsSelector = createSelector(
  state => selectors.actionsSelector(state),
  state => state.currProjectId,
  (actions, currProjectId) => {
    const currActions = actions[currProjectId] || [];

    return currActions.slice().sort((a, b) => (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1));
  }
);
