import 'styles/Dashboard/CreateProject.scss';
import React from 'react';
import * as apiService from 'services/apiService';
import * as selectors from 'selectors/selectors';
import * as actions from 'actions/actions';
import dispatchable from 'actions/dispatchable';
import { Modal, Input, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { withRouter } from 'react-router-dom';

class CreateProject extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.defaultState();
  }

  defaultState = () => {
    return {
      showCreateModal: false,
      projectName: '',
      loading: false,
      errorMessage: ''
    };
  };

  goToAccount = () => {
    this.props.history.push('/Account');

    Modal.destroyAll();
  };

  showCreateProjectModal = () => {
    const ownedProjectCount = this.props.projects.filter(p => p.ownerEmail === this.props.user.email).length;

    if (ownedProjectCount >= this.props.permissions.project_limit) {
      Modal.info({
        title: `You have reached your project limit`,
        content: (
          <div>
            <p>
              To create more projects, please{' '}
              <span className='accountLink' onClick={this.goToAccount}>
                upgrade
              </span>{' '}
              your account
            </p>
          </div>
        )
      });

      return;
    }

    this.setState({ showCreateModal: true });
  };

  createProject = async () => {
    this.setState({ loading: true });

    // doing project limit validation in server for now
    try {
      await apiService.createProject(this.state.projectName);
    } catch (e) {
      this.setState({ errorMessage: 'Could not create project. You have reached your project limit.', loading: false });
      return;
    }

    this.props.dispatch(actions.getAndSetProjects());
    this.setState(this.defaultState());
  };

  cancelModal = () => {
    this.setState(this.defaultState());
  };

  updateName = e => {
    this.setState({ projectName: e.target.value });
  };

  render() {
    const buttonText = this.state.loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : 'Create';

    return (
      <>
        <div className='createProjectCard blueBg blueHover' onClick={this.showCreateProjectModal}>
          <div className='createProjectCardTitle'>Create Project</div>
          <div className='createProjectCardIcon'>
            <AiOutlinePlusCircle />
          </div>
        </div>
        <Modal visible={this.state.showCreateModal} footer={null} title={null} closable={false} onCancel={this.cancelModal} destroyOnClose={true}>
          <div className='createProjectModalTitle'>Create Project</div>
          <div className='createProjectModalLabel'>Name:</div>
          <Input className='projectNameInput' onChange={this.updateName} placeholder='Project Name' />
          <div className='createProjectModalFooter'>
            <Button type='primary' size='large' className='createProjectButton' onClick={this.createProject} disabled={!this.state.projectName}>
              {buttonText}
            </Button>
          </div>
          <div className='createProjectModalError'>{this.state.errorMessage}</div>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: selectors.userSelector(state),
    projects: selectors.projectsSelector(state),
    permissions: selectors.permissionsSelector(state)
  };
}

export default withRouter(dispatchable(CreateProject, mapStateToProps));
