import 'styles/Project/PreviewModal.scss';
import React from 'react';
import dispatchable from 'actions/dispatchable';
import { Button } from 'antd';
import * as selectors from 'selectors/selectors';
import MicroModal from 'micromodal';

class PreviewModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      secondsToGo: parseInt(props.refreshTime)
    };

    this.timer = null;
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  showModal = () => {
    MicroModal.show(this.props.id, {
      onClose: () => this.closeModal()
    });

    clearInterval(this.timer);

    if (this.props.isOptional) {
      return;
    }

    this.timer = setInterval(() => {
      this.setState({ secondsToGo: this.state.secondsToGo - 1 });

      if (this.state.secondsToGo <= 0) {
        MicroModal.close(this.props.id);
      }
    }, 1000);
  };

  closeModal = () => {
    clearInterval(this.timer);
    this.timer = null;

    this.setState({ secondsToGo: parseInt(this.props.refreshTime) });
  };

  render() {
    return (
      <div className='previewModalBtn'>
        <Button onClick={this.showModal} type='primary' size={this.props.btnSize || 'default'}>
          Preview Modal
        </Button>
        <div className='previewModal micromodal-slide' id={this.props.id} aria-hidden='true'>
          <div className='modal__overlay' tabIndex='-1'>
            <div className='modal__container' role='dialog' aria-modal='true' aria-labelledby='modal-1-title'>
              <header className='modal__header'>
                <h2 className='modal__title' id='modal-1-title'>
                  {this.props.mainTitle}
                </h2>
              </header>
              <main className='modal__content' id='modal-1-content'>
                <p>{this.props.mainText}</p>
                {!this.props.isOptional && <p className='modal__countdown'>Auto refreshing in {this.state.secondsToGo} seconds.</p>}
              </main>
              <footer className='modal__footer'>
                {this.props.isOptional && (
                  <button className='modal__btn jellysync_cancel_button' data-micromodal-close>
                    Cancel
                  </button>
                )}
                <button className='modal__btn modal__btn-primary' data-micromodal-close>
                  Update
                </button>
              </footer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currProject: selectors.currProjectSelector(state)
  };
}

export default dispatchable(PreviewModal, mapStateToProps);
