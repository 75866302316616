import 'styles/Project/GeneralConfig.scss';
import React from 'react';
import dispatchable from 'actions/dispatchable';
import * as selectors from 'selectors/selectors';
import * as actions from 'actions/actions';
import * as apiService from 'services/apiService';
import { Row, Col, Switch, Tooltip } from 'antd';
import { FaInfoCircle } from 'react-icons/fa';
import { isEmpty } from 'lodash';
import ConfigSection from 'components/Project/ConfigSection';

class GeneralConfig extends React.Component {
  updateAttr = async checked => {
    const projectActions = this.props.currAction.actions;

    if (checked) {
      // add action to array
      if (!projectActions.includes(this.props.actionName)) {
        this.props.currAction.actions = projectActions.concat(this.props.actionName);
      }
    } else {
      // remove action from array
      this.props.currAction.actions = projectActions.filter(a => a !== this.props.actionName);
    }

    // update action
    await apiService.updateAction(this.props.currProject.projectId, this.props.currAction.actionId, this.props.currAction);
    this.props.dispatch(actions.getAndSetActions(this.props.currProject.projectId));
  };

  render() {
    if (isEmpty(this.props.currAction)) {
      return null;
    }

    const children = this.props.currAction.actions.includes(this.props.actionName) ? this.props.children : null;
    const infoText = this.props.permissions.only_clear_all ? this.props.upgradeInfo : this.props.info;

    return (
      <ConfigSection>
        <Row>
          <Col span={24}>
            <div className='generalConfigLabelWrapper'>
              <div className='generalConfigHeader'>
                <div className='generalConfigTitle'>{this.props.configName}</div>
                <Tooltip title={infoText}>
                  <FaInfoCircle />
                </Tooltip>
              </div>
              <div>
                <Switch loading={this.props.loadingAction} checked={this.props.currAction.actions.includes(this.props.actionName)} onChange={this.updateAttr} />
              </div>
            </div>
          </Col>
        </Row>
        {children}
      </ConfigSection>
    );
  }
}

function mapStateToProps(state) {
  return {
    currProject: selectors.currProjectSelector(state),
    currAction: selectors.currActionSelector(state),
    permissions: selectors.projectPermissionsSelector(state),
    loadingAction: selectors.loadingActionSelector(state)
  };
}

export default dispatchable(GeneralConfig, mapStateToProps);
