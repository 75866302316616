import * as actions from 'actions/actions';
import * as apiService from 'services/apiService';

export const projectsMiddleware = store => next => async action => {
  switch (action.type) {
    case actions.GET_AND_SET_PROJECTS: {
      const projects = await apiService.getProjects();
      store.dispatch(actions.setProjects(projects));
      store.dispatch(actions.setLoadingProjects(false));

      return next(action);
    }
    default: {
      return next(action);
    }
  }
};
