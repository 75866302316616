import * as actions from 'actions/actions';

export const projectsReducer = (state = [], action) => {
  switch (action.type) {
    case actions.SET_PROJECTS: {
      return action.projects.slice();
    }
    default: {
      return state;
    }
  }
};
