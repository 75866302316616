export const SET_PROJECTS = 'SET_PROJECTS';
export const GET_AND_SET_PROJECTS = 'GET_AND_SET_PROJECTS';
export const SET_CURR_PROJECT_ID = 'SET_CURR_PROJECT_ID';
export const SET_LOADING_PROJECTS = 'SET_LOADING_PROJECTS';
export const SET_CURR_ACTION_ID = 'SET_CURR_ACTION_ID';
export const SET_ACTIONS = 'SET_ACTIONS';
export const GET_AND_SET_ACTIONS = 'GET_AND_SET_ACTIONS';
export const SET_LOADING_ACTION = 'SET_LOADING_ACTION';

export function setProjects(projects) {
  return {
    type: SET_PROJECTS,
    projects
  };
}

export function getAndSetProjects() {
  return {
    type: GET_AND_SET_PROJECTS
  };
}

export function setCurrProjectId(projectId) {
  return {
    type: SET_CURR_PROJECT_ID,
    projectId
  };
}

export function setLoadingProjects(isLoading) {
  return {
    type: SET_LOADING_PROJECTS,
    isLoading
  };
}

export function setActions(actions, projectId) {
  return {
    type: SET_ACTIONS,
    actions,
    projectId
  };
}

export function setCurrActionId(actionId) {
  return {
    type: SET_CURR_ACTION_ID,
    actionId
  };
}

export function getAndSetActions(projectId) {
  return {
    type: GET_AND_SET_ACTIONS,
    projectId
  };
}

export function setLoading(isLoading) {
  return {
    type: SET_LOADING_ACTION,
    isLoading
  };
}
