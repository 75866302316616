import 'styles/Project/Project.scss';
import React from 'react';
import dispatchable from 'actions/dispatchable';
import * as selectors from 'selectors/selectors';
import ProjectDetails from 'components/Project/ProjectDetails';
import Installation from 'components/Project/Installation';
import DeleteProject from 'components/Project/DeleteProject';
import CollabConfig from 'components/Project/CollabConfig';
import LeaveProject from 'components/Project/LeaveProject';
import ApiAccess from 'components/Project/ApiAccess';
import { Row, Col } from 'antd';

class ProjectInfo extends React.Component {
  render() {
    const deleteProject = this.props.user.email === this.props.currProject.ownerEmail ? <DeleteProject /> : <LeaveProject />;

    return (
      <Row>
        <Col lg={{ span: 16, offset: 1 }} xs={{ span: 23, offset: 1 }}>
          <ProjectDetails />
          <br />
          <CollabConfig />
          <br />
          <Installation />
          <br />
          <ApiAccess />
          <br />
          <Row>
            <Col className='configTable redBorder' span={24}>
              <Row>
                <Col span={24}>{deleteProject}</Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    currProject: selectors.currProjectSelector(state),
    user: selectors.userSelector(state)
  };
}

export default dispatchable(ProjectInfo, mapStateToProps);
