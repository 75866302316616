import 'styles/Project/Project.scss';
import 'styles/Project/ApiAccess.scss';
import React from 'react';
import dispatchable from 'actions/dispatchable';
import * as selectors from 'selectors/selectors';
import * as actions from 'actions/actions';
import * as apiService from 'services/apiService';
import { Row, Col, Modal, Button, Spin, Tooltip } from 'antd';
import { FaInfoCircle } from 'react-icons/fa';
import { LoadingOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import CopyButton from 'components/Project/CopyButton';

const { confirm } = Modal;

class ApiAccess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      loading: false
    };
  }

  showConfirm = () => {
    confirm({
      title: 'Are you sure you want to generate a new api key?',
      icon: <ExclamationCircleFilled style={{ color: '#FD6161' }} />,
      content: 'This will invalidate all existing keys',
      okText: 'Generate New Key',
      okButtonProps: {
        type: 'danger'
      },
      onOk: () => {
        this.generateApiKey();
      },
      maskClosable: true
    });
  };

  generateApiKey = async () => {
    this.setState({ loading: true });
    await apiService.generateApiKey(this.props.currProject.projectId);
    this.props.dispatch(actions.getAndSetProjects());
    this.setState({ loading: false });
  };

  render() {
    const buttonContent = this.state.loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : 'Generate New Key';

    if (!this.props.permissions.api_access) {
      return (
        <Row>
          <Col className='configTable secondaryBg' span={24}>
            <Row>
              <Col span={24}>
                <div className='projectDetailsHeader'>
                  <div className='configTitleNoBody'>API Access</div>
                  <div className='upgradeTooltip'>
                    <Tooltip title='Upgrade your account to get API access'>
                      <FaInfoCircle size={22} />
                    </Tooltip>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col className='configTable secondaryBg' span={24}>
          <Row>
            <Col span={24}>
              <div className='projectDetailsHeader'>
                <div className='configTitle'>API Access</div>
                <Button disabled={this.state.loading} type='danger' onClick={this.showConfirm}>
                  {buttonContent}
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className='apiAccessLabel'>Api Key</div>
              <div className='projectDetailValue'>{this.props.currProject.apiKey}</div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className='apiLabelWrapper'>
                <div className='apiAccessLabel'>Endpoint</div>
                <CopyButton text={`${process.env.REACT_APP_FUNCTIONS_URL}/v1/projects/${this.props.currProject.projectId}/pushUpdate/:actionId`} />
              </div>
              <div className='apiPostWrapper'>
                <CodeMirror
                  value={`POST ${process.env.REACT_APP_FUNCTIONS_URL}/v1/projects/${this.props.currProject.projectId}/pushUpdate/:actionId`}
                  options={{
                    mode: 'http',
                    theme: this.props.theme === 'darkTheme' ? 'material-darker' : 'default',
                    lineNumbers: false,
                    readOnly: true,
                    lineWrapping: true
                  }}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <div className='apiLabelWrapper'>
                <div className='apiAccessLabel'>Headers</div>
                <CopyButton text={`x-api-key: ${this.props.currProject.apiKey}`} />
              </div>
              <div className='apiHeadersWrapper'>
                <CodeMirror
                  value={`x-api-key: ${this.props.currProject.apiKey}`}
                  options={{
                    mode: 'javascript',
                    theme: this.props.theme === 'darkTheme' ? 'material-darker' : 'default',
                    lineNumbers: true,
                    readOnly: true
                  }}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    currProject: selectors.currProjectSelector(state),
    theme: selectors.themeSelector(state),
    permissions: selectors.projectPermissionsSelector(state)
  };
}

export default dispatchable(ApiAccess, mapStateToProps);
