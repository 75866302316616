import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from 'reducers/rootReducer';
import { enableBatching } from 'redux-batched-actions';
import * as middleware from 'middleware/middleware';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const appliedMiddleware = [thunk, ...Object.values(middleware)];
const appliedMiddleware = [thunk, ...Object.values(middleware)];
const store = createStore(enableBatching(rootReducer), {}, composeEnhancers(applyMiddleware(...appliedMiddleware)));

export default store;
