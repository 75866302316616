import * as actions from 'actions/actions';

export const currProjectIdReducer = (state = '', action) => {
  switch (action.type) {
    case actions.SET_CURR_PROJECT_ID: {
      return action.projectId;
    }
    default: {
      return state;
    }
  }
};
