import { Button, Row, Col, Modal } from 'antd';
import * as apiService from 'services/apiService';
import React from 'react';

class LakeModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };
  }

  closeModal = () => {
    this.setState({ showModal: false });
  };

  changeTier = async () => {
    const priceId = process.env.REACT_APP_LAKE_PRICE_ID;
    const subId = this.props.subscription.id;
    await apiService.changeTier(subId, priceId);
    window.location.reload();
  };

  render() {
    return (
      <>
        <Button onClick={() => this.setState({ showModal: true })} type='link' size='large'>
          Downgrade to Lake
        </Button>
        <Modal closable={false} footer={null} onCancel={this.closeModal} visible={this.state.showModal}>
          <Row>
            <Col className='pricingCol' span={24}>
              <div className="pricingHeader">
                <div className='pricingLabel'>Lake</div>
                <div className='pricingLabel'>$20</div>
              </div>
              <div className='pricingFeaturesWrapper'>
                <div className='pricingFeature'>200 Concurrent Connections</div>
                <hr />
                <div className='pricingFeature'>3 Projects</div>
                <hr />
                <div className='pricingFeature'>5 Collaborators per project</div>
                <hr />
                <div className='pricingFeature'>4 Update Configurations per project</div>
                <hr />
                <div className='pricingFeature'>Optional And Mandatory Updates</div>
                <hr />
                <div className='pricingFeature'>Tracked Update History</div>
                <hr />
                <div className='pricingFeature'>API Access</div>
                <hr />
                <div className='pricingFeature strike'>Customizable Notification Modal</div>
                <hr />
                <div className='pricingFeature strike'>Only clear All or None of Local, Session, and Cookies</div>
                <hr />
                <div className='pricingFeature strike'>Targeted Updates</div>
                <hr />
              </div>
              <Button type='primary' size='large' block onClick={this.changeTier}>
                Downgrade to Lake
              </Button>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

export default LakeModal;
