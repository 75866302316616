import 'styles/Project/History.scss';
import React from 'react';
import dispatchable from 'actions/dispatchable';
import * as selectors from 'selectors/selectors';
import moment from 'moment';
import { Row, Col } from 'antd';
import { FaClock } from 'react-icons/fa';

class HistoryDetails extends React.Component {
  renderHistory = () => {
    return this.props.history.map(h => {
      return (
        <div key={h.timestamp} className='historySnapshotWrapper secondaryBg'>
          <div className='historySnapshotBubble bgText' />
          <div className='historySnapshotName'>{h.name}</div>
          <div>{moment(h.timestamp).format('MM/DD/YYYY hh:mm A')}</div>
        </div>
      );
    });
  };

  render() {
    return (
      <Row>
        <Col lg={{ span: 16, offset: 1 }} xs={{ span: 23, offset: 1 }}>
          <div className='historyLine bgText' />
          <Row>
            <Col span={24}>
              <div className='historyHeader'>
                <FaClock className='historyClockIcon' />
                History
              </div>
            </Col>
          </Row>
          {this.renderHistory()}
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    history: selectors.projectHistorySelector(state)
  };
}

export default dispatchable(HistoryDetails, mapStateToProps);
