import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import '@ionic/react';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// @ts-ignore
import { Provider } from 'react-redux';
import store from 'store/store';
import { firebase } from 'fire/firebase';
import * as serviceWorker from './serviceWorker';
import * as apiService from 'services/apiService';
import Login from 'components/Login/Login';
import * as bugsnagService from 'services/bugsnagService';
import VerifyEmail from 'components/Login/VerifyEmail';

bugsnagService.initialize();

const app = (
  <Provider store={store}>
    <App />
  </Provider>
);

firebase.auth().onAuthStateChanged(async user => {
  if (user) {
    if (!user.emailVerified) {
      await user.sendEmailVerification();

      ReactDOM.render(<VerifyEmail user={user} />, document.getElementById('root'));
      return;
    }

    window.Jellysync.setUser(user.email);
    apiService.logUser();
    firebase.analytics().setUserId(user.uid);
    firebase.analytics().setUserProperties({ userId: user.uid, email: user.email });
    bugsnagService.setUser(user);

    ReactDOM.render(app, document.getElementById('root'));
  } else {
    ReactDOM.render(<Login />, document.getElementById('root'));
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
