import * as actions from 'actions/actions';

export const actionsReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.SET_ACTIONS: {
      state[action.projectId] = action.actions.slice();

      return Object.assign({}, state);
    }
    default: {
      return state;
    }
  }
};
