import { get, post, del } from 'clients/axiosWrapper';

const baseUrl = process.env.REACT_APP_FUNCTIONS_URL;

export function logUser() {
  post(`${baseUrl}/user/logUser`);
}

export async function createProject(name) {
  return (
    await post(`${baseUrl}/projects`, {
      name
    })
  ).data;
}

export async function pushUpdate(projectId, actionId, userIds = []) {
  return (
    await post(`${baseUrl}/projects/${projectId}/pushUpdate/${actionId}`, {
      userIds
    })
  ).data;
}

export async function deleteProject(projectId) {
  return (await del(`${baseUrl}/projects/${projectId}`)).data;
}

export async function getProjects() {
  return (await get(`${baseUrl}/projects`)).data;
}

export async function getUser() {
  return (await get(`${baseUrl}/user`)).data;
}

export async function updateProject(project) {
  return (
    await post(`${baseUrl}/projects/${project.projectId}/updateProject`, {
      project
    })
  ).data;
}

export async function updateTheme(theme) {
  return (
    await post(`${baseUrl}/user/updateTheme`, {
      theme
    })
  ).data;
}

export async function isWhitelisted() {
  return (await get(`${baseUrl}/user/isWhitelisted`)).data;
}

export async function getCollabs(projectId) {
  return (await get(`${baseUrl}/projects/${projectId}/projectEmails`)).data;
}

export async function addCollaborator(projectId, email) {
  return (
    await post(`${baseUrl}/projects/${projectId}/addCollaborator`, {
      email
    })
  ).data;
}

export async function removeCollaborator(projectId, email) {
  return (
    await post(`${baseUrl}/projects/${projectId}/removeCollaborator`, {
      email
    })
  ).data;
}

export async function generateApiKey(projectId) {
  return (await post(`${baseUrl}/projects/${projectId}/generateApiKey`)).data;
}

export async function getActions(projectId) {
  return (await get(`${baseUrl}/projects/${projectId}/actions`)).data;
}

export async function getCheckoutSession(priceId) {
  return (await get(`${baseUrl}/checkout?priceId=${priceId}`)).data;
}

export async function getPortalSession(customerId) {
  return (await get(`${baseUrl}/portal?customerId=${customerId}`)).data;
}

export async function getUserSubscription() {
  return (await get(`${baseUrl}/subscription`)).data;
}

export async function getPermissions(prodId) {
  return (await get(`${baseUrl}/permissions?prodId=${prodId}`)).data;
}

export async function createAction(projectId, name) {
  return (
    await post(`${baseUrl}/projects/${projectId}/actions`, {
      name
    })
  ).data;
}

export async function changeTier(subId, priceId) {
  return (
    await post(`${baseUrl}/changeTier`, {
      subId,
      priceId
    })
  ).data;
}

export async function updateAction(projectId, actionId, action) {
  return (
    await post(`${baseUrl}/projects/${projectId}/actions/${actionId}`, {
      action
    })
  ).data;
}

export async function deleteAction(projectId, actionId) {
  return (await del(`${baseUrl}/projects/${projectId}/actions/${actionId}`)).data;
}

export async function searchForConnections(projectId, userId) {
  return (await get(`${baseUrl}/projects/${projectId}/searchConnections?userId=${userId}`)).data;
}
