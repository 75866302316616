import { combineReducers } from 'redux';
import { projectsReducer } from 'reducers/projectsReducer';
import { currProjectIdReducer } from 'reducers/currProjectIdReducer';
import { userReducer } from 'reducers/userReducer';
import { loadingProjectsReducer } from 'reducers/loadingProjectsReducer';
import { actionsReducer } from 'reducers/actionsReducer';
import { currActionIdReducer } from 'reducers/currActionIdReducer';
import { subscriptionReducer } from 'reducers/subscriptionReducer';
import { permissionsReducer } from 'reducers/permissionsReducer';
import { loadingActionReducer } from 'reducers/loadingActionReducer';

const rootReducer = combineReducers({
  projects: projectsReducer,
  currProjectId: currProjectIdReducer,
  user: userReducer,
  loadingProjects: loadingProjectsReducer,
  actions: actionsReducer,
  currActionId: currActionIdReducer,
  subscription: subscriptionReducer,
  permissions: permissionsReducer,
  loadingAction: loadingActionReducer
});

export default rootReducer;
