import 'styles/Project/Project.scss';
import 'styles/Project/LocalStorageConfig.scss';
import React from 'react';
import dispatchable from 'actions/dispatchable';
import * as selectors from 'selectors/selectors';
import * as actions from 'actions/actions';
import * as apiService from 'services/apiService';
import { Row, Col, Input, Switch, Button } from 'antd';
import { FaTrash } from 'react-icons/fa';
import { isEmpty } from 'lodash';

class StorageConfig extends React.Component {
  state = {
    storageKey: ''
  };

  updateStorageKey = e => {
    this.setState({ storageKey: e.target.value });
  };

  updateAction = async action => {
    await apiService.updateAction(this.props.currProject.projectId, action.actionId, action);
    this.props.dispatch(actions.getAndSetActions(this.props.currProject.projectId));
  };

  setClearStorage = checked => {
    const action = Object.assign({}, this.props.currAction);
    action[this.props.clearType] = checked;
    this.updateAction(action);
  };

  setWhitelistStorage = checked => {
    const action = Object.assign({}, this.props.currAction);
    action[this.props.whitelistType] = checked;
    this.updateAction(action);
  };

  addStorageKey = () => {
    if (!this.state.storageKey) {
      return;
    }

    const action = Object.assign({}, this.props.currAction);

    if (!action[this.props.storageKeys]) {
      action[this.props.storageKeys] = [];
    }

    action[this.props.storageKeys].push(this.state.storageKey);
    this.updateAction(action);
    this.setState({ storageKey: '' });
  };

  removeStorageKey = key => {
    const action = Object.assign({}, this.props.currAction);
    action[this.props.storageKeys] = action[this.props.storageKeys].filter(storageKey => storageKey !== key);
    this.updateAction(action);
  };

  getKeys = () => {
    if (isEmpty(this.props.currAction[this.props.storageKeys])) {
      return null;
    }

    const keys = this.props.currAction[this.props.storageKeys].sort().map((storageKey, index) => {
      return (
        <div key={index} className='localStorageKeyWrapper'>
          <div onClick={() => this.removeStorageKey(storageKey)} className='localStorageTrashIcon'>
            <FaTrash />
          </div>
          <div>{storageKey}</div>
        </div>
      );
    });

    const keyText = this.props.currAction[this.props.whitelistType] ? 'Remove the Following Keys:' : 'Remove Everything but the Following Keys:';

    return (
      <Row>
        <Col span={24}>
          <div className='localStorageSwitchContainer'>
            <Switch loading={this.props.loadingAction} checked={this.props.currAction[this.props.whitelistType]} onChange={this.setWhitelistStorage} />
            <div className='localStorageKeyLabel'>{keyText}</div>
          </div>
          {keys}
        </Col>
      </Row>
    );
  };

  renderKeyInput = () => {
    if (this.props.currAction[this.props.clearType]) {
      return null;
    }

    const keys = this.getKeys();

    return [
      <Row key={0}>
        <Col md={11} xs={12}>
          <Input value={this.state.storageKey} placeholder={`${this.props.storageType} Key`} onChange={this.updateStorageKey} />
        </Col>
        <Col span={2} offset={1}>
          <Button onClick={this.addStorageKey} type='primary'>
            Add
          </Button>
        </Col>
      </Row>,
      <span key={1}>{keys}</span>
    ];
  };

  render() {
    if (this.props.permissions.only_clear_all) {
      return null;
    }

    const keyInput = this.renderKeyInput();

    return (
      <Row>
        <Col className='configTable secondaryBg' span={24}>
          <Row>
            <Col span={24}>
              <div className='localStorageSwitchContainer'>
                <div className='localStorageLabel'>Clear all {this.props.storageType}: </div>
                <Switch loading={this.props.loadingAction} checked={this.props.currAction[this.props.clearType]} onChange={this.setClearStorage} />
              </div>
            </Col>
          </Row>
          {keyInput}
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    currProject: selectors.currProjectSelector(state),
    currAction: selectors.currActionSelector(state),
    permissions: selectors.projectPermissionsSelector(state),
    loadingAction: selectors.loadingActionSelector(state)
  };
}

export default dispatchable(StorageConfig, mapStateToProps);
