import 'styles/Project/Project.scss';
import React from 'react';
import dispatchable from 'actions/dispatchable';
import * as selectors from 'selectors/selectors';
import * as actions from 'actions/actions';
import { withRouter } from 'react-router';
import { Row, Col, Drawer } from 'antd';
import Sidenav from 'components/Project/Sidenav';
import { MdKeyboardArrowRight } from 'react-icons/md';

class SidenavWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      drawerVisible: false
    };
  }

  openDrawer = () => {
    this.setState({ drawerVisible: true });
  };

  closeDrawer = () => {
    this.setState({ drawerVisible: false });
  };

  selectAction = actionId => {
    this.props.dispatch(actions.setCurrActionId(actionId));
  };

  getActions = () => {
    return this.props.actions.map(action => (
      <div key={action.actionId} onClick={() => this.selectAction(action.actionId)}>
        {action.name}
      </div>
    ));
  };

  render() {
    return (
      <>
        <Row>
          <Col className='sideNav' xs={{ span: 1 }} lg={{ span: 0 }}>
            <div className='drawerToggle secondaryBg secondaryHover' onClick={this.openDrawer}>
              <MdKeyboardArrowRight className='drawerToggleIcon' />
            </div>
          </Col>
          <Col className='configTable secondaryBg sideNav' xs={{ span: 0 }} lg={{ span: 6 }} xl={{ span: 4, offset: 2 }}>
            <Sidenav />
          </Col>
          <Col xs={{ span: 21, offset: 2 }} sm={{ span: 23, offset: 0 }} lg={{ span: 18 }}>
            {this.props.page}
          </Col>
        </Row>
        <Drawer
          closable={false}
          onClose={this.closeDrawer}
          visible={this.state.drawerVisible}
          getContainer={false}
          placement='left'
          style={{ position: 'absolute' }}
        >
          <div className='drawerContents bg'>
            <Row>
              <Col className='configTable sideNav' span={24}>
                <Sidenav />
              </Col>
            </Row>
          </div>
        </Drawer>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    actions: selectors.actionsSelector(state)
  };
}

export default withRouter(dispatchable(SidenavWrapper, mapStateToProps));
