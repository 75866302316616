import * as actions from 'actions/actions';
import * as apiService from 'services/apiService';

export const actionsMiddleware = store => next => async action => {
  switch (action.type) {
    case actions.GET_AND_SET_ACTIONS: {
      try {
        store.dispatch(actions.setLoading(true));
        const projectActions = await apiService.getActions(action.projectId);
        store.dispatch(actions.setActions(projectActions, action.projectId));
        store.dispatch(actions.setLoading(false));
      } catch (e) {
        store.dispatch(actions.setLoading(false));
      }

      return next(action);
    }
    default: {
      return next(action);
    }
  }
};
