import * as apiClient from 'clients/apiClient';

export function logUser() {
  apiClient.logUser();
}

export async function createProject(name) {
  await apiClient.createProject(name);
}

export async function deleteProject(projectId) {
  await apiClient.deleteProject(projectId);
}

export function getProjects() {
  return apiClient.getProjects();
}

export function updateProject(project) {
  return apiClient.updateProject(project);
}

export function updateTheme(theme) {
  return apiClient.updateTheme(theme);
}

export function pushUpdate(projectId, actionId, userIds) {
  return apiClient.pushUpdate(projectId, actionId, userIds);
}

export function getUser() {
  return apiClient.getUser();
}

export function isWhitelisted() {
  return apiClient.isWhitelisted();
}

export function getCollabs(projectId) {
  return apiClient.getCollabs(projectId);
}

export function addCollaborator(projectId, email) {
  return apiClient.addCollaborator(projectId, email);
}

export function removeCollaborator(projectId, email) {
  return apiClient.removeCollaborator(projectId, email);
}

export function generateApiKey(projectId) {
  return apiClient.generateApiKey(projectId);
}

export function createAction(projectId, name) {
  return apiClient.createAction(projectId, name);
}

export function updateAction(projectId, actionId, action) {
  return apiClient.updateAction(projectId, actionId, action);
}

export function deleteAction(projectId, actionId) {
  return apiClient.deleteAction(projectId, actionId);
}

export function getActions(projectId) {
  return apiClient.getActions(projectId);
}

export function getCheckoutSession(priceId) {
  return apiClient.getCheckoutSession(priceId);
}

export function getPortalSession(customerId) {
  return apiClient.getPortalSession(customerId);
}

export function getUserSubscription() {
  return apiClient.getUserSubscription();
}

export function getPermissions(prodId) {
  return apiClient.getPermissions(prodId);
}

export function changeTier(subId, priceId) {
  return apiClient.changeTier(subId, priceId);
}

export function searchForConnections(projectId, userId) {
  return apiClient.searchForConnections(projectId, userId);
}
