import 'styles/Project/Project.scss';
import 'styles/Project/ProjectDetails.scss';
import 'styles/Dashboard/CreateProject.scss';
import React from 'react';
import dispatchable from 'actions/dispatchable';
import * as selectors from 'selectors/selectors';
import * as actions from 'actions/actions';
import * as apiService from 'services/apiService';
import { Row, Col, Modal, Input, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PushUpdateModal from 'components/Shared/PushUpdateModal';

class ProjectDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEditModal: false,
      projectName: props.currProject.name,
      savingEdits: false
    };
  }

  showEditModal = () => {
    this.setState({ showEditModal: true });
  };

  closeEditModal = () => {
    this.setState({ showEditModal: false, projectName: this.props.currProject.name });
  };

  updateName = e => {
    this.setState({ projectName: e.target.value });
  };

  saveUpdates = async () => {
    this.setState({ savingEdits: true });

    await apiService.updateProject({
      ...this.props.currProject,
      name: this.state.projectName
    });

    this.props.dispatch(actions.getAndSetProjects());
    this.setState({ showEditModal: false, savingEdits: false });
  };

  render() {
    const buttonText = this.state.savingEdits ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : 'Save';

    return (
      <Row>
        <Col className='configTable secondaryBg' span={24}>
          <Row>
            <Col span={24}>
              <div className='projectDetailsHeader'>
                <div className='configTitle'>Project Details</div>
                <div className='editProjectDetails blueColor' onClick={this.showEditModal}>
                  Edit
                </div>
              </div>
              <Modal visible={this.state.showEditModal} footer={null} title={null} closable={false} onCancel={this.closeEditModal}>
                <div className='createProjectModalLabel'>Name:</div>
                <Input className='projectNameInput' onChange={this.updateName} placeholder='Project Name' value={this.state.projectName} />
                <div className='createProjectModalFooter'>
                  <Button type='primary' size='large' className='createProjectButton' onClick={this.saveUpdates} disabled={!this.state.projectName}>
                    {buttonText}
                  </Button>
                </div>
              </Modal>
            </Col>
          </Row>
          <Row>
            <Col span={24} className='projectDetailAttr'>
              <div className='projectConfigLabelWrapper'>
                <div>
                  <div className='projectDetailLabel'>Name:</div>
                  <div className='projectDetailValue'>{this.props.currProject.name}</div>
                  <div className='projectDetailLabel'>Project ID:</div>
                  <div className='projectDetailValue'>{this.props.currProject.projectId}</div>
                </div>
                <PushUpdateModal project={this.props.currProject} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    currProject: selectors.currProjectSelector(state)
  };
}

export default dispatchable(ProjectDetails, mapStateToProps);
