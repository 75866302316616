import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyDRP5cBqpyLVUugQWZtYbSjaqrQlxYs2G8',
  authDomain: 'jellysync.firebaseapp.com',
  databaseURL: 'https://jellysync.firebaseio.com',
  projectId: 'jellysync',
  storageBucket: 'jellysync.appspot.com',
  messagingSenderId: '757397537758',
  measurementId: 'G-ZZ4NRJBEVW',
  appId: '1:757397537758:web:7dd1645537045fdfcb534f'
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export { firebase };
