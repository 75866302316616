import 'styles/Project/ActionConfig.scss';
import React from 'react';
import dispatchable from 'actions/dispatchable';
import * as selectors from 'selectors/selectors';
import * as actions from 'actions/actions';
import * as apiService from 'services/apiService';
import { Row, Col, Modal, Input, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ConfigSection from 'components/Project/ConfigSection';
import PushUpdateModal from 'components/Shared/PushUpdateModal';

class ActionConfig extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEditModal: false,
      actionName: props.currAction.name,
      savingEdits: false
    };
  }

  componentDidUpdate(prevProps) {
    if ((!this.state.actionName && this.props.currAction.name) || prevProps.currAction.actionId !== this.props.currAction.actionId) {
      this.setState({ actionName: this.props.currAction.name });
    }
  }

  showEditModal = () => {
    this.setState({ showEditModal: true });
  };

  closeEditModal = () => {
    this.setState({ showEditModal: false, actionName: this.props.currAction.name });
  };

  updateName = e => {
    this.setState({ actionName: e.target.value });
  };

  saveUpdates = async () => {
    this.setState({ savingEdits: true });

    await apiService.updateAction(this.props.currProject.projectId, this.props.currAction.actionId, { name: this.state.actionName });

    this.props.dispatch(actions.getAndSetActions(this.props.currProject.projectId));
    this.setState({ showEditModal: false, savingEdits: false });
  };

  render() {
    const buttonText = this.state.savingEdits ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : 'Save';

    return (
      <ConfigSection>
        <Row>
          <Col span={24}>
            <div className='actionConfigHeader'>
              <div className='actionConfigTitle'>Action Details</div>
              <div className='editActionDetails blueColor' onClick={this.showEditModal}>
                Edit
              </div>
            </div>
            <Modal visible={this.state.showEditModal} footer={null} title={null} closable={false} onCancel={this.closeEditModal}>
              <div className='editActionModalLabel'>Edit Action Name</div>
              <Input className='editActionModalInput' onChange={this.updateName} placeholder='Action Name' value={this.state.actionName} />
              <div className='editActionModalFooter'>
                <Button type='primary' size='large' className='editActionButton' onClick={this.saveUpdates} disabled={!this.state.actionName}>
                  {buttonText}
                </Button>
              </div>
            </Modal>
          </Col>
        </Row>
        <Row>
          <Col span={24} className='actionDetailsAttr'>
            <div className='actionConfigLabelWrapper'>
              <div>
                <div className='actionDetailsLabel'>Name:</div>
                <div className='actionDetailsValue'>{this.props.currAction.name}</div>
                <div className='actionDetailsLabel'>Action ID:</div>
                <div className='actionDetailsValue'>{this.props.currAction.actionId}</div>
              </div>
              <PushUpdateModal project={this.props.currProject} action={this.props.currAction} />
            </div>
          </Col>
        </Row>
      </ConfigSection>
    );
  }
}

function mapStateToProps(state) {
  return {
    currProject: selectors.currProjectSelector(state),
    currAction: selectors.currActionSelector(state)
  };
}

export default dispatchable(ActionConfig, mapStateToProps);
