import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

export function initialize() {
  if (isActive()) {
    Bugsnag.start({
      apiKey: '8bf24334d479cdc8b6c8672327c47915',
      appVersion: '1.0.0',
      plugins: [new BugsnagPluginReact()]
    });
  }
}

export function setUser(currUser) {
  if (isActive()) {
    Bugsnag.setUser(currUser.uid, currUser.email, currUser.displayName);
  }
}

function isActive() {
  return process.env.REACT_APP_ENVIRONMENT === 'PRD';
}
