import { createSelector } from 'reselect';

export const themeSelector = createSelector(
  state => state.user,
  user => {
    if (user && user.theme) {
      return user.theme;
    }

    return 'lightTheme';
  }
);
