import { createSelector } from 'reselect';
import * as selectors from 'selectors/selectors';

export const currProjectSelector = createSelector(
  state => selectors.projectsSelector(state),
  state => state.currProjectId,
  (projects, currProjectId) => {
    return projects.find(p => p.projectId === currProjectId) || {};
  }
);
