import { Button, Row, Col, Modal } from 'antd';
import * as apiService from 'services/apiService';
import React from 'react';

class OceanModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };
  }

  closeModal = () => {
    this.setState({ showModal: false });
  };

  changeTier = async () => {
    const priceId = process.env.REACT_APP_OCEAN_PRICE_ID;
    const subId = this.props.subscription.id;
    await apiService.changeTier(subId, priceId);
    window.location.reload();
  };

  render() {
    return (
      <>
        <Button onClick={() => this.setState({ showModal: true })} type='link' size='large'>
          Upgrade to Ocean
        </Button>
        <Modal closable={false} footer={null} onCancel={this.closeModal} visible={this.state.showModal}>
          <Row>
            <Col className='pricingCol' span={24}>
              <div className="pricingHeader">
                <div className='pricingLabel'>Ocean</div>
                <div className='pricingLabel'>$60</div>
              </div>
              <div className='pricingFeaturesWrapper'>
                <div className='pricingFeature'>2000 Concurrent Connections</div>
                <hr />
                <div className='pricingFeature'>10 Projects</div>
                <hr />
                <div className='pricingFeature'>12 Collaborators per project</div>
                <hr />
                <div className='pricingFeature'>8 Update Configurations per project</div>
                <hr />
                <div className='pricingFeature'>Optional And Mandatory Updates</div>
                <hr />
                <div className='pricingFeature'>Tracked Update History</div>
                <hr />
                <div className='pricingFeature'>API Access</div>
                <hr />
                <div className='pricingFeature'>Customizable Notification Modal</div>
                <hr />
                <div className='pricingFeature'>Only clear All or None of Local, Session, and Cookies</div>
                <hr />
                <div className='pricingFeature'>Targeted Updates</div>
                <hr />
              </div>
              <Button type='primary' size='large' block onClick={this.changeTier}>
                Upgrade to Ocean
              </Button>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

export default OceanModal;
