import { Button, Row, Col, Modal } from 'antd';
import React from 'react';

class CheckoutModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };
  }

  closeModal = () => {
    this.setState({ showModal: false });
  };

  checkout = priceId => {
    this.props.checkout(priceId);
    this.closeModal();
  };

  render() {
    return (
      <>
        <Button onClick={() => this.setState({ showModal: true })} type='primary' size='large'>
          Upgrade Account
        </Button>
        <Modal closable={false} footer={null} onCancel={this.closeModal} visible={this.state.showModal}>
          <Row>
            <Col className='pricingCol' xs={24} md={12}>
              <div className="pricingHeader">
                <div className='pricingLabel'>Lake</div>
                <div className='pricingLabel'>$20</div>
              </div>
              <div className='pricingFeaturesWrapper'>
                <div className='pricingFeature'>200 Concurrent Connections</div>
                <hr />
                <div className='pricingFeature'>3 Projects</div>
                <hr />
                <div className='pricingFeature'>5 Collaborators per project</div>
                <hr />
                <div className='pricingFeature'>4 Update Configurations per project</div>
                <hr />
                <div className='pricingFeature'>Optional And Mandatory Updates</div>
                <hr />
                <div className='pricingFeature'>Tracked Update History</div>
                <hr />
                <div className='pricingFeature'>API Access</div>
                <hr />
                <div className='pricingFeature strike'>Customizable Notification Modal</div>
                <hr />
                <div className='pricingFeature strike'>Only clear All or None of Local, Session, and Cookies</div>
                <hr />
                <div className='pricingFeature strike'>Targeted Updates</div>
                <hr />
              </div>
              <Button className="lakeCheckoutBtn" type='primary' size='large' block onClick={() => this.checkout(process.env.REACT_APP_LAKE_PRICE_ID)}>
                Checkout
              </Button>
            </Col>
            <Col className='pricingCol' xs={24} md={12}>
              <div className="pricingHeader">
                <div className='pricingLabel'>Ocean</div>
                <div className='pricingLabel'>$60</div>
              </div>
              <div className='pricingFeaturesWrapper'>
                <div className='pricingFeature'>2000 Concurrent Connections</div>
                <hr />
                <div className='pricingFeature'>10 Projects</div>
                <hr />
                <div className='pricingFeature'>12 Collaborators per project</div>
                <hr />
                <div className='pricingFeature'>8 Update Configurations per project</div>
                <hr />
                <div className='pricingFeature'>Optional And Mandatory Updates</div>
                <hr />
                <div className='pricingFeature'>Tracked Update History</div>
                <hr />
                <div className='pricingFeature'>API Access</div>
                <hr />
                <div className='pricingFeature'>Customizable Notification Modal</div>
                <hr />
                <div className='pricingFeature'>Only clear All or None of Local, Session, and Cookies</div>
                <hr />
                <div className='pricingFeature'>Targeted Updates</div>
                <hr />
              </div>
              <Button className="oceanCheckoutBtn" type='primary' size='large' block onClick={() => this.checkout(process.env.REACT_APP_OCEAN_PRICE_ID)}>
                Checkout
              </Button>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

export default CheckoutModal;
