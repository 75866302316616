import 'styles/Project/Channels.scss';
import React from 'react';
import { Row, Col, Input } from 'antd';
import * as apiService from 'services/apiService';
import { FaUsers } from 'react-icons/fa';
import dispatchable from 'actions/dispatchable';
import * as selectors from 'selectors/selectors';
import moment from 'moment';
import PushUpdateModal from 'components/Shared/PushUpdateModal';

class ChannelDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: '',
      isSearching: false,
      connections: []
    };
  }

  searchForConnection = async value => {
    if (this.state.isSearching) {
      return;
    }

    if (value.length < 3) {
      this.setState({ errorMessage: 'Search value must be at least 3 characters' });
      return;
    } else {
      this.setState({ errorMessage: '' });
    }

    this.setState({ isSearching: true });

    const connections = await apiService.searchForConnections(this.props.currProject.projectId, value);
    this.setState({ connections, isSearching: false });
  };

  renderConnections = () => {
    return this.state.connections.map(c => {
      return (
        <div key={c.channelKey} className='channelSnapshotWrapper secondaryBg'>
          <div className='channelSnapshotName'>{c.userId}</div>
          <div className='channelFlex'>
            <div>Last Updated: {moment(c.lastUpdated).format('MM/DD/YYYY hh:mm A')}</div>
            <PushUpdateModal userIds={[c.userId]} project={this.props.currProject} />
          </div>
        </div>
      );
    });
  };

  render() {
    return (
      <Row>
        <Col lg={{ span: 16, offset: 1 }} xs={{ span: 23, offset: 1 }}>
          <Row>
            <Col span={24}>
              <div className='channelsHeader'>
                <FaUsers className='channelsUsersIcon' />
                Connections
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={12}>
              <div className='channelSearchWrapper'>
                <Input.Search onSearch={this.searchForConnection} enterButton='Search' placeholder='Search User IDs...' />
              </div>
              <div className='channelSearchError'>{this.state.errorMessage}</div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>{this.renderConnections()}</Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    currProject: selectors.currProjectSelector(state)
  };
}

export default dispatchable(ChannelDetails, mapStateToProps);
