import 'styles/Project/DeleteProject.scss';
import React from 'react';
import dispatchable from 'actions/dispatchable';
import * as actions from 'actions/actions';
import * as selectors from 'selectors/selectors';
import * as apiService from 'services/apiService';
import { Button, Modal, Spin } from 'antd';
import { LoadingOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';

const { confirm } = Modal;

class DeleteProject extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDeleting: false
    };
  }

  showConfirm = () => {
    confirm({
      title: `Are you sure you want to delete ${this.props.currProject.name}?`,
      icon: <ExclamationCircleFilled style={{ color: '#FD6161' }} />,
      content: 'This project cannot be recovered after deletion!',
      okText: 'Delete Project',
      okButtonProps: {
        type: 'danger'
      },
      onOk: () => {
        this.deleteProject();
      },
      maskClosable: true
    });
  };

  deleteProject = async () => {
    if (this.state.isDeleting) {
      return;
    }

    this.setState({ isDeleting: true });

    try {
      await apiService.deleteProject(this.props.currProject.projectId);
    } catch (e) {
      this.setState({ isDeleting: false });
      return;
    }

    this.setState({ isDeleting: false });
    this.props.dispatch(actions.getAndSetProjects());
    this.props.history.replace('/Dashboard');
  };

  render() {
    const buttonText = this.state.isDeleting ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : 'Delete';

    return (
      <>
        <div className='deleteProject'>
          <div>
            <div className='deleteProjectTitle'>Delete Project</div>
            <div className='deleteProjectWarning'>Caution! Once you delete this project it is gone forever.</div>
          </div>
          <Button disabled={this.state.isDeleting} onClick={this.showConfirm} type='danger'>
            {buttonText}
          </Button>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currProject: selectors.currProjectSelector(state)
  };
}

export default withRouter(dispatchable(DeleteProject, mapStateToProps));
