import 'styles/Project/ModalConfig.scss';
import React from 'react';
import dispatchable from 'actions/dispatchable';
import * as selectors from 'selectors/selectors';
import * as actions from 'actions/actions';
import { Row, Col, Tooltip, Input, Switch, Radio } from 'antd';
import { FaInfoCircle, FaClock } from 'react-icons/fa';
import * as apiService from 'services/apiService';
import { debounce } from 'debounce';
import TextArea from 'antd/lib/input/TextArea';
import PreviewModal from 'components/Project/PreviewModal';
import ConfigSection from 'components/Project/ConfigSection';

class ModalConfig extends React.Component {
  updateAction = async action => {
    await apiService.updateAction(this.props.currProject.projectId, action.actionId, action);
    this.props.dispatch(actions.getAndSetActions(this.props.currProject.projectId));
  };

  updateModalTitle = debounce(value => {
    const action = Object.assign({}, this.props.currAction, { modalMainTitle: value });
    this.updateAction(action);
  }, 500);

  updateModalText = debounce(value => {
    const action = Object.assign({}, this.props.currAction, { modalMainText: value });
    this.updateAction(action);
  }, 500);

  updateRefreshTime = debounce(value => {
    const action = Object.assign({}, this.props.currAction, { refreshTime: value });
    this.updateAction(action);
  }, 500);

  updateShowModal = checked => {
    const action = Object.assign({}, this.props.currAction, { showModal: checked });
    this.updateAction(action);
  };

  setUpdateIsOptional = checked => {
    const action = Object.assign({}, this.props.currAction, { updateIsOptional: checked.target.value === 1 ? true : false });
    this.updateAction(action);
  };

  getModalConfig = () => {
    if (!this.props.currAction.showModal) {
      return null;
    }

    return (
      <>
        <div className='modalTextArea'>
          <div className='modalTextAreaSection'>
            <div className='modalTextAreaLabel'>Title:</div>
            {!this.props.permissions.custom_modal ? (
              <div className='modalTextAreaNoSubscription'>{this.props.currAction.modalMainTitle}</div>
            ) : (
              <TextArea
                key={this.props.currAction.actionId}
                className='bg textColor'
                onChange={e => this.updateModalTitle(e.target.value)}
                defaultValue={this.props.currAction.modalMainTitle}
                rows={1}
              />
            )}
          </div>
          <div>
            <div className='modalTextAreaLabel'>Message:</div>
            {!this.props.permissions.custom_modal ? (
              <div className='modalTextAreaNoSubscription'>{this.props.currAction.modalMainText}</div>
            ) : (
              <TextArea
                key={this.props.currAction.actionId}
                className='bg textColor'
                onChange={e => this.updateModalText(e.target.value)}
                defaultValue={this.props.currAction.modalMainText}
                rows={4}
              />
            )}
          </div>
        </div>
        <br />
        <Row>
          <Col span={24}>
            <div className='timeRow'>
              <div>
                <div>
                  <Radio.Group value={this.props.currAction.updateIsOptional ? 1 : 0} onChange={this.setUpdateIsOptional}>
                    <Radio value={1}>
                      <span className='textColor'>
                        Update is <b>OPTIONAL</b>
                      </span>
                    </Radio>
                    <Radio value={0}>
                      <span className='textColor'>
                        Update is <b>REQUIRED</b>
                      </span>
                    </Radio>
                  </Radio.Group>
                </div>
                {!this.props.currAction.updateIsOptional && (
                  <div className='setTimeContainer'>
                    Refresh In:
                    <div className='setTimeContent'>
                      <div className='modalTimeInput'>
                        <Input
                          className='bg textColor'
                          onChange={e => this.updateRefreshTime(e.target.value)}
                          defaultValue={this.props.currAction.refreshTime}
                          size='large'
                          prefix={<FaClock />}
                          type='number'
                          max={59}
                          min={1}
                          step={1}
                        />
                      </div>
                      <div>Seconds</div>
                    </div>
                  </div>
                )}
              </div>
              <PreviewModal
                refreshTime={this.props.currAction.refreshTime}
                mainTitle={this.props.currAction.modalMainTitle}
                mainText={this.props.currAction.modalMainText}
                id='actionDetailsPreviewModal'
                isOptional={this.props.currAction.updateIsOptional}
              />
            </div>
          </Col>
        </Row>
      </>
    );
  };

  render() {
    const infoText = !this.props.permissions.custom_modal
      ? 'The notification text alerting your users of a new update. **Upgrade your account to customize the text.**'
      : 'Configure what you want to say to your users when pushing an update';

    return (
      <ConfigSection>
        <div className='flexConfigTitle'>
          <div className='modalConfigTitle'>
            <div className='modalConfigTitleText'>Update Modal</div>
            <Tooltip title={infoText}>
              <FaInfoCircle />
            </Tooltip>
          </div>
          <div>
            <Switch checked={this.props.currAction.showModal} onChange={this.updateShowModal} />
          </div>
        </div>
        {this.getModalConfig()}
      </ConfigSection>
    );
  }
}

function mapStateToProps(state) {
  return {
    currProject: selectors.currProjectSelector(state),
    currAction: selectors.currActionSelector(state),
    permissions: selectors.projectPermissionsSelector(state)
  };
}

export default dispatchable(ModalConfig, mapStateToProps);
