import 'styles/Project/LeaveProject.scss';
import React from 'react';
import dispatchable from 'actions/dispatchable';
import * as actions from 'actions/actions';
import * as selectors from 'selectors/selectors';
import * as apiService from 'services/apiService';
import { Button, Modal, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';

class LeaveProject extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirmLeaveModal: false,
      isLeaving: false
    };
  }

  showModal = () => {
    this.setState({ showConfirmLeaveModal: true });
  };

  cancelModal = () => {
    this.setState({ showConfirmLeaveModal: false });
  };

  leaveProject = async () => {
    if (this.state.isLeaving) {
      return;
    }

    this.setState({ isLeaving: true });

    try {
      await apiService.removeCollaborator(this.props.currProject.projectId, this.props.user.email);
    } catch (e) {
      this.setState({ isLeaving: false });
      return;
    }

    this.setState({ isLeaving: false });
    this.props.dispatch(actions.getAndSetProjects());
    this.cancelModal();
    this.props.history.replace('/Dashboard');
  };

  render() {
    const buttonText = this.state.isLeaving ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : 'Leave';

    return (
      <>
        <div className='leaveProject'>
          <div className='leaveProjectTitle'>Leave Project</div>
          <Button onClick={this.showModal} type='danger'>
            Leave Project
          </Button>
        </div>
        <Modal
          closable={false}
          footer={
            <div className='leaveProjectModalFooter'>
              <Button className='leaveProjectModalButtonCancel' type='default' onClick={this.cancelModal}>
                Cancel
              </Button>
              <Button className='leaveProjectModalButton' type='danger' onClick={this.leaveProject}>
                {buttonText}
              </Button>
            </div>
          }
          onCancel={this.cancelModal}
          visible={this.state.showConfirmLeaveModal}
        >
          <div className='modalBody'>
            <div className='leaveModalTitle'>Are you sure you want to leave {this.props.currProject.name}?</div>
          </div>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currProject: selectors.currProjectSelector(state),
    user: selectors.userSelector(state)
  };
}

export default withRouter(dispatchable(LeaveProject, mapStateToProps));
