import 'styles/Project/Project.scss';
import 'styles/Project/ProjectDetails.scss';
import 'styles/Dashboard/CreateProject.scss';
import React from 'react';
import dispatchable from 'actions/dispatchable';
import * as selectors from 'selectors/selectors';
import { Row, Col, Tooltip } from 'antd';
import { FaInfoCircle } from 'react-icons/fa';
import ManageCollabsModal from 'components/Project/ManageCollabsModal';

class CollabConfig extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };
  }

  showModal = () => {
    this.setState({ showModal: true });
  };

  onCancel = () => {
    this.setState({ showModal: false });
  };

  getCollabs = () => {
    return this.props.collabs.map((collab, index) => {
      if (collab === this.props.currProject.ownerEmail) {
        return (
          <div key={index}>
            {collab} <i>(owner)</i>
          </div>
        );
      }

      return <div key={index}>{collab}</div>;
    });
  };

  renderEditButton = () => {
    if (this.props.permissions.collaborator_limit === 1) {
      return (
        <div className='upgradeTooltip'>
          <Tooltip title='Upgrade your account to share this project with other collaborators'>
            <FaInfoCircle size={22} />
          </Tooltip>
        </div>
      );
    }

    return (
      <div className='editProjectDetails blueColor' onClick={this.showModal}>
        Edit
      </div>
    );
  };

  render() {
    const collabs = this.getCollabs();

    return (
      <Row>
        <Col className='configTable secondaryBg' span={24}>
          <div className='projectDetailsHeader'>
            <div className='configTitle'>Collaboration</div>
            {this.renderEditButton()}
          </div>
          <Row>
            <Col>{collabs}</Col>
          </Row>
        </Col>
        <ManageCollabsModal showModal={this.state.showModal} onCancel={this.onCancel} collabs={collabs} />
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    currProject: selectors.currProjectSelector(state),
    collabs: selectors.currCollabsSelector(state),
    permissions: selectors.projectPermissionsSelector(state)
  };
}

export default dispatchable(CollabConfig, mapStateToProps);
