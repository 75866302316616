import 'styles/Dashboard/CreateProject.scss';
import React from 'react';
import * as apiService from 'services/apiService';
import * as selectors from 'selectors/selectors';
import * as actions from 'actions/actions';
import dispatchable from 'actions/dispatchable';
import { Modal, Input, Button, Spin } from 'antd';
import { LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';

class CreateAction extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.defaultState();
  }

  defaultState = () => {
    return {
      showCreateModal: false,
      actionName: '',
      loading: false,
      errorMessage: ''
    };
  };

  goToAccount = () => {
    this.props.history.push('/Account');

    Modal.destroyAll();
  };

  showCreateActionModal = () => {
    if (this.props.currProject.actionIds.length >= this.props.permissions.action_limit) {
      Modal.info({
        title: `You have reached your action limit`,
        content: (
          <div>
            <p>
              To create more actions, please{' '}
              <span className='accountLink' onClick={this.goToAccount}>
                upgrade
              </span>{' '}
              your account
            </p>
          </div>
        )
      });

      return;
    }

    this.setState({ showCreateModal: true });
  };

  createAction = async () => {
    this.setState({ loading: true });

    // doing project limit validation in server for now
    try {
      await apiService.createAction(this.props.currProject.projectId, this.state.actionName);
    } catch (e) {
      this.setState({ errorMessage: 'Could not create action. Please refresh the page and try again.', loading: false });
      return;
    }

    this.props.dispatch(actions.getAndSetActions(this.props.currProject.projectId));
    this.setState(this.defaultState());
  };

  cancelModal = () => {
    this.setState(this.defaultState());
  };

  updateName = e => {
    this.setState({ actionName: e.target.value });
  };

  render() {
    const buttonText = this.state.loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : 'Create';

    return (
      <>
        <PlusCircleOutlined size={20} onClick={this.showCreateActionModal} />
        <Modal visible={this.state.showCreateModal} footer={null} title={null} closable={false} onCancel={this.cancelModal} destroyOnClose={true}>
          <div className='createProjectModalTitle'>Create Action</div>
          <div className='createProjectModalLabel'>Name:</div>
          <Input className='projectNameInput' onChange={this.updateName} placeholder='Action Name' />
          <div className='createProjectModalFooter'>
            <Button type='primary' size='large' className='createProjectButton' onClick={this.createAction} disabled={!this.state.actionName}>
              {buttonText}
            </Button>
          </div>
          <div className='createProjectModalError'>{this.state.errorMessage}</div>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currProject: selectors.currProjectSelector(state),
    permissions: selectors.permissionsSelector(state)
  };
}

export default withRouter(dispatchable(CreateAction, mapStateToProps));
