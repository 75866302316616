import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Navbar, NavbarBrand, Collapse, NavbarToggler, Nav, NavItem, NavLink, NavbarText } from 'reactstrap';
import dispatchable from 'actions/dispatchable';
import * as selectors from 'selectors/selectors';
import { firebase } from 'fire/firebase';
import ThemeSwitch from 'components/Dashboard/ThemeSwitch';
import { ReactComponent as Logo } from 'images/Logo.svg';

class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  close = () => {
    this.setState({ isOpen: false });
  };

  isLight = () => {
    return this.props.theme === 'lightTheme';
  };

  signout = async () => {
    await firebase.auth().signOut();
    window.location.reload();
  };

  render() {
    return (
      <div onBlur={this.close}>
        <Navbar fixed='top' light={this.isLight()} dark={!this.isLight()} className='secondaryBg' expand='md'>
          <NavbarBrand tag={Link} to='/Dashboard'>
            <div className='leftMenuWrapper'>
              <Logo className='menuLogo' />
              <div className='menuEmail'>JellySync ({this.props.user.email})</div>
            </div>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className='ml-auto' navbar>
              <NavbarText>
                <ThemeSwitch />
              </NavbarText>
              <NavItem>
                <NavLink active={this.props.location.pathname === '/Dashboard'} tag={Link} to='/Dashboard'>
                  Dashboard
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink active={this.props.location.pathname === '/Account'} tag={Link} to='/Account'>
                  Account
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink active={true} onClick={this.signout}>
                  Logout
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    theme: selectors.themeSelector(state),
    user: selectors.userSelector(state)
  };
}

export default withRouter(dispatchable(Menu, mapStateToProps));
