import 'styles/Login/Login.scss';
import 'styles/Login/Login.scss';
import React from 'react';
import { message, Button } from 'antd';
import { ReactComponent as Logo } from 'images/LogoWithText.svg';
import { firebase } from 'fire/firebase';

class VerifyEmail extends React.Component {
  logout = () => {
    firebase.auth().signOut();
  };

  resendEmail = async () => {
    await this.props.user.sendEmailVerification();

    message.success('A verification email has been sent');
  };

  render() {
    return (
      <div className='loginWrapper'>
        <div className='logoContainer'>
          <Logo />
        </div>
        <div className='verifyEmailMessage'>Please verify your email address</div>
        <div className='verifyEmailMessage'>
          A verification email has been sent to: <b>{this.props.user.email}</b>
        </div>
        <Button type='primary' size='large' onClick={this.resendEmail} className='verifyEmailResendLink'>
          Resend Verification Email
        </Button>
        <Button type='link' size='large' onClick={this.logout} className='verifyEmailResendLink'>
          Logout
        </Button>
      </div>
    );
  }
}

export default VerifyEmail;
