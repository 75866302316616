import 'styles/Account/Account.scss';
import React from 'react';
import * as apiService from 'services/apiService';
import * as selectors from 'selectors/selectors';
import { Button, Row, Col } from 'antd';
import { isEmpty } from 'lodash';
import { loadStripe } from '@stripe/stripe-js';
import dispatchable from 'actions/dispatchable';
import moment from 'moment';
import CheckoutModal from 'components/Account/CheckoutModal';
import OceanModal from 'components/Account/OceanModal';
import LakeModal from 'components/Account/LakeModal';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

class Account extends React.Component {
  checkout = async priceId => {
    const session = await apiService.getCheckoutSession(priceId);
    const stripe = await stripePromise;

    await stripe.redirectToCheckout({
      sessionId: session.id
    });
  };

  portal = async () => {
    const session = await apiService.getPortalSession(this.props.subscription.customer);

    window.open(session.url, '_blank');
  };

  sessionBtn = () => {
    if (isEmpty(this.props.subscription)) {
      return <CheckoutModal checkout={this.checkout} />;
    }

    return (
      <Button className='accountSessionBtn' onClick={this.portal} type='primary' size='large'>
        Manage Subscription
      </Button>
    );
  };

  trialEnd = () => {
    if (!this.props.subscription.trial_end) {
      return null;
    }

    return (
      <div className='accountText'>
        Trial Ends: <b>{moment.unix(this.props.subscription.trial_end).format('MM/DD/YYYY')}</b>
      </div>
    );
  };

  nextBilling = () => {
    if (isEmpty(this.props.subscription)) {
      return null;
    }

    if (this.props.subscription.cancel_at) {
      return (
        <div className='accountText'>
          Cancel On: <b>{moment.unix(this.props.subscription.cancel_at).format('MM/DD/YYYY')}</b>
        </div>
      );
    }

    return (
      <div className='accountText'>
        Next Auto Payment: <b>{moment.unix(this.props.subscription.current_period_end).format('MM/DD/YYYY')}</b>
      </div>
    );
  };

  changeTierBtn = () => {
    if (isEmpty(this.props.subscription)) {
      return null;
    }

    return this.props.permissions.name === 'Lake' ? (
      <OceanModal subscription={this.props.subscription} />
    ) : (
      <LakeModal subscription={this.props.subscription} />
    );
  };

  planName = () => {
    if (isEmpty(this.props.subscription)) {
      return 'Pond';
    }

    if (this.props.subscription.cancel_at) {
      return `${this.props.permissions.name} (Not Renewing)`;
    }

    return this.props.permissions.name;
  };

  render() {
    return (
      <Row>
        <Col className='accountSectionWrapper secondaryBg' sm={24} lg={{ offset: 4, span: 16 }}>
          <div className='accountHeader'>
            Account: <b>{this.props.user.email}</b>
          </div>
          <div className='accountText'>
            Plan: <b>{this.planName()}</b>
          </div>
          {this.trialEnd()}
          {this.nextBilling()}
          {this.sessionBtn()}
        </Col>
        <Col sm={24} lg={{ offset: 4, span: 16 }} className='changeTierBtn'>
          {this.changeTierBtn()}
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    subscription: selectors.subscriptionSelector(state),
    user: selectors.userSelector(state),
    permissions: selectors.permissionsSelector(state)
  };
}

export default dispatchable(Account, mapStateToProps);
