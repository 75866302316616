import * as actions from 'actions/actions';
import * as apiService from 'services/apiService';
import { isEmpty } from 'lodash';

export const userMiddleware = store => next => async action => {
  switch (action.type) {
    case actions.GET_USER: {
      const user = await apiService.getUser();
      store.dispatch(actions.setUser(user));

      const subscription = (await apiService.getUserSubscription()) || {};
      store.dispatch(actions.setSubscription(subscription));

      const permissions = await apiService.getPermissions(!isEmpty(subscription) ? subscription.plan.product : null);
      store.dispatch(actions.setPermissions(permissions));

      return next(action);
    }
    case actions.SET_THEME: {
      apiService.updateTheme(action.theme);

      return next(action);
    }
    default: {
      return next(action);
    }
  }
};
