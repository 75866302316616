import 'styles/Project/Project.scss';
import 'styles/Project/Sidenav.scss';
import React from 'react';
import dispatchable from 'actions/dispatchable';
import * as selectors from 'selectors/selectors';
import * as actions from 'actions/actions';
import { withRouter } from 'react-router';
import { Divider } from 'antd';
import CreateAction from 'components/Project/CreateAction';

class Sidenav extends React.Component {
  selectAction = actionId => {
    let pathname = `/Projects/${this.props.currProject.projectId}`;

    if (actionId) {
      pathname += `/Actions/${actionId}`;
    }

    this.props.history.push(pathname);
    this.props.dispatch(actions.setCurrActionId(actionId));
  };

  goToHistory = () => {
    this.props.history.push(`/Projects/${this.props.currProject.projectId}/History`);
    this.props.dispatch(actions.setCurrActionId(''));
  };

  goToChannels = () => {
    this.props.history.push(`/Projects/${this.props.currProject.projectId}/Connections`);
    this.props.dispatch(actions.setCurrActionId(''));
  };

  getActions = () => {
    return this.props.actions.map(action => {
      const selected = action.actionId === this.props.currActionId ? 'blueBg' : 'secondaryHover';

      return (
        <div className={`sidenavAction ${selected}`} key={action.actionId} onClick={() => this.selectAction(action.actionId)}>
          {action.name}
        </div>
      );
    });
  };

  isSelected = type => {
    switch (type) {
      case 'action': {
        return this.props.currActionId;
      }
      case 'history': {
        return this.props.location.pathname.includes('History');
      }
      case 'connections': {
        return this.props.location.pathname.includes('Connections');
      }
      case 'project': {
        return !this.props.currActionId && !this.props.location.pathname.includes('History') && !this.props.location.pathname.includes('Connections');
      }
      default: {
        return false;
      }
    }
  };

  connectionOption = () => {
    if (!this.props.permissions.search_connections) {
      return null;
    }

    return (
      <div onClick={this.goToChannels} className={`${this.isSelected('connections') ? 'blueBg' : 'secondaryHover'} sidenavHistory`}>
        Connections
      </div>
    );
  };

  render() {
    return (
      <>
        <div className={`sidenavProjectName ${this.isSelected('project') ? 'blueBg' : 'secondaryHover'}`} onClick={() => this.selectAction('')}>
          {this.props.currProject.name}
        </div>
        <Divider />
        <div className='sidenavHeaderWrapper'>
          <div className='sidenavHeader'>Actions</div>
          <CreateAction />
        </div>
        <div className='sidenavActionsWrapper'>{this.getActions()}</div>
        <Divider />
        <div onClick={this.goToHistory} className={`${this.isSelected('history') ? 'blueBg' : 'secondaryHover'} sidenavHistory`}>
          History
        </div>
        {this.connectionOption()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currProject: selectors.currProjectSelector(state),
    actions: selectors.currActionsSelector(state),
    currActionId: state.currActionId,
    permissions: selectors.permissionsSelector(state)
  };
}

export default withRouter(dispatchable(Sidenav, mapStateToProps));
